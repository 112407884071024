<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div class="row" v-if="conference != null">
                <div class="col-12">
                    <div class="py-5 card bg-white border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="d-flex flex-row  border-0 w-100 p-0 mb-3 shadow-none">
                            <div class="mx-4 my-1 p-0 ml-1 btn-round-md rounded-xl bg-current">
                                <i class="fab fa-chromecast font-xl text-white"></i>
                            </div>
                            <div class="card-body p-0 pb-2 border-bottom border-dark">
                                <div class="row justify-content-between col-md-12">
                                    <h6 class="fw-600 text-grey-500 font-xssss">{{conference.zoom_id.start_time | formatDateTime}}</h6>
                                </div>
                                <div class="row justify-content-between col-md-12">
                                    <h2 class="font-lg text-grey-900 fw-600">{{conference.zoom_id.topic}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 ml-5 pl-5">
                            <h4 class="text-grey-800 font-xs mt-4 fw-700">Agenda</h4>
                            <p class="font-xsss fw-600 lh-28 text-grey-600 mb-0 pl-0">{{conference.zoom_id.agenda}}</p>
                            <a :href="conference.zoom_id.join_url" target="_blank" class="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200">Join</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 text-center">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
        </div>
        <div class="col-12 text-center py-3" v-else>
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            conference: {},
            paramsId: this.$route.params.idCourse,
            conferenceId: this.$route.params.idConference,
            isLoad: true
        }
    },
    created(){
        this.getConference()
    },
    methods:{
        async getConference(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/mycourse/conference/detail?conference_id=${this.conferenceId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.conference = res.data
            }).catch((err) => {
                this.isLoad = false
                console.log(err)
            })
        },
    }
}
</script>
